$color-light-brand: #9E9793;
$color-brand: #544741;
$color-orange: #E26700;
$btn_color: #544741;


$font-mincho: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
html{
	font-size: 16px;
	font-family: sans-serif;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-family: 游ゴシック体, 'Yu Gothic', YuGothic, メイリオ, Meiryo, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
	font-weight: 500;
	text-align: justify;
}
.font-serif{
	font-family: $font-mincho;
}
a{
	color: $color-brand;
	text-decoration: none;
	&:not([class^="btn"]):hover{
		text-decoration: underline;
	}
}
body{
	position: relative;
	height: 100%;
	@media screen and (min-width:768px) {
		min-width: 992px;
	}
}
img{
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}
p{
	line-height: 1.8;
}
@media screen and (min-width:768px) {
	a[href^="tel:"] {
			pointer-events: none;
			color: initial;
	}
}
