.mainFooter{
	background-image: url(/img/footer-deco.png);
	background-position: right 0;
	background-size: 441px 55px;
	padding-top: 54px;
	&--txt{
		font-size: 14px;
	}
	&--link{
		display: block;
		margin-bottom: .5em;
	}
}
.mainFooter--inner{
	color: #fff;
	padding-top: 1em;
	padding-bottom: 1em;
	text-align: center;
	background-color: $color-brand;
	a{
		color: #fff;
		text-decoration: underline;

	}
}
