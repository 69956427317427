.contact-mv{
	background-image: url(/img/contact-mv-bg.jpg);
}
input,select,textarea{
	border: 1px solid #000;
	background-size: contain;
	background-position: right 0;
	border-radius: 4px;
	padding: .3em 1em .3em 1em;
}
select{
	padding-right: 3em;
	background-image: url(/img/select-arrow.png);
}
input{
	width: 100%;
}
textarea{
	width: 100%;
}
.contact-lead{
	max-width: 717px;
	&--top{
		font-size: 18px;
		padding: 1em 2rem;
		@media screen and (min-width:768px) {
			font-size: 20px;
		}
	}
	&--copy{
		border: 2px solid $color-brand;
		padding: 1em 2rem;
		font-size: 16px;
		.strong{
			position: relative;
			&::before{
				content: "";
				background-color: #FF7BAA;
				height: 6px;
				position: absolute;
				bottom: -2px;
				width: 100%;
				display: block;
				z-index: -1;
			}
		}
	}
}
.contact-body{
	margin-top: 4rem;
	padding: 1px;
	&--inner{
		margin-top: 1rem;
		margin-bottom: 2rem;
		background-color: #fff;
		padding-top: 20px;
		padding-bottom: 110px;
		@media screen and (min-width:768px) {
			margin-right: 20px;
			margin-left: 20px;
		}
	}
	&--ttl{
		font-size: 20px;
		text-align: center;
		@media screen and (min-width:768px) {
			font-size: 24px;
		}
	}
}
.contact-table{
	width: 90%;
	max-width: 642px;
	padding: 1px;
	margin: auto;
	margin-bottom: 2rem;
	@media screen and (min-width:768px) {
		width: 642px;
	}
	th,td{
		vertical-align: top;
		color: #666666;
		@media screen and (max-width:767px) {
			display: block;
		}
		@media screen and (min-width:768px) {
			padding-top: 1.5em;
		}
	}
	th{
		text-align: left;
		padding-top: 1.5em;
		@media screen and (min-width:768px) {
			width:10em;
			padding-top: 2em;
		}
	}
	td{
		@media screen and (max-width:767px) {
			padding-top: 0;
		}
	}
	&__confirm{
		th{
			font-weight: normal;
		}
		td{
			font-weight: bold;
			text-align: left;
			padding-top: 1.5em;
			@media screen and (min-width:768px) {
				padding-top: 2em;
			}

		}
	}
}
.contact-btn--col{
	margin-top: 1rem;
	padding-left: 15px;
	padding-right: 15px;
	width: initial;
}
.contact-btn{
	width: 256px;
	color: #fff;
	font-size: 16px;
	background-color: #979797;
	border: none;
	border-radius: 2px;
	padding-top: 0.2em;
	padding-bottom: 0.4em;
	padding-left: 0;
	padding-right: 0;
	@media screen and (min-width:768px) {
		font-size: 30px;
	}
	&__confirm{
		background-color: #D44047;
	}
}
.txt-error{
	color: #ff7baa;
	font-weight: bold;
}
