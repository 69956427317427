.header-wrap{
	background-color: #fff;
	box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: .3);
	position: fixed;
	width: 100%;
}
.header {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	@media screen and (min-width:768px) {
		padding: 20px 0;
	}

	.header-logo {
		font-size: 20px;
		width: 195px;
	}
}

.header-nav-button {
	display: none;
}

.header-nav {
	a {
		text-decoration: none;
		font-size: 14px;
		&:hover{
			opacity: 0.75;
		}
		&.is-current{
			opacity: 0.6;
		}
	}

	li {
		display: inline-block;
		margin: 0 10px;
	}
}

.header-nav-wrap.open {
	display: block;
}

.header-nav-wrap.close {
	display: none;
}

@media screen and (min-width: 768px) {
	.header-nav-wrap {
		display: block !important;
	}
	.header-nav{
		a{
			display: inline-block;
			width: 116px;
			text-align: center;
			color: #fff;
			background-color:  $btn_color;
			padding: 0.5em .5em;
			border-radius: 1.5em;
		}
	}
}

.header-content {
	padding: 20px;
}

@media screen and (max-width: 767px) {
	.header-nav-button {
		display: block;
		cursor: pointer;
	}

	.header-nav-wrap {
		position: fixed;
		left: 0;
		top: 0;
		display: none;
		z-index: 10;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;

		.header-nav {
			height: 100%;
			position: relative;
			overflow-x: hidden;
			overflow-y: auto;
		}

		li {
			display: block;
			margin: 2em;

			a {
				color: #fff;
				font-size: 18px;
			}
		}
	}

	/*メニューボタンのエフェクト*/
	.header-nav-button,
	.header-nav-button span {
		display: inline-block;
		transition: all 0.4s;
		box-sizing: border-box;
	}

	.header-nav-button {
		z-index: 20;
		position: relative;
		width: 40px;
		height: 36px;
	}

	.header-nav-button{
		span {
		position: absolute;
		left: 0;
		width: 100%;
		height: 4px;
		background-color: $color-brand;
		border-radius: 4px;
		}
		&.active span{
			background-color: #fff;
		}
	}

	.header-nav-button span:nth-of-type(1) {
		top: 0;
	}

	.header-nav-button span:nth-of-type(2) {
		top: 16px;
	}

	.header-nav-button span:nth-of-type(3) {
		bottom: 0;
	}

	.header-nav-button.active span:nth-of-type(1) {
		-webkit-transform: translateY(16px) rotate(-45deg);
		transform: translateY(16px) rotate(-45deg);
	}

	.header-nav-button.active span:nth-of-type(2) {
		opacity: 0;
	}

	.header-nav-button.active span:nth-of-type(3) {
		-webkit-transform: translateY(-16px) rotate(45deg);
		transform: translateY(-16px) rotate(45deg);
	}
}
