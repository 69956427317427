.works-mv{
	background-image: url(/img/works-mv-bg.jpg);
}
.works-content{

	max-width: 244px;
	img{
	}
	p{
		text-align: center;
		@media screen and (min-width:768px) {
			font-size: 20px;
		}
	}
}
.works-img1{
	margin-top: 2em;
	@media screen and (min-width:768px) {
		max-width:  80%;
		margin-left:auto ;
		margin-right: auto;
	}
}
.works-img2{
	margin-top: 2em;
}
.works-mainTxt{
	margin-top: .5em;
	@media screen and (min-width:768px) {
		font-size: 20px;
	}
	&.small{
		@media screen and (min-width:768px) {
			font-size: 17px;
		}
	}
}

