.about-mv{
	background-color: #C4B1A8;
}
.about-gmap{
	height: 425px;
	margin: auto;
	iframe{
		width: 100%;
		height: 100%;
	}
}
.about-outline{
	background-image: url(/img/about-outline-bg.jpg);
	background-size: cover;
	background-position: center bottom;
	padding-top: 10px;
	padding-bottom: 20px;
	@media screen and (min-width:768px) {

	}
	&--inner{
		padding: 1px 20px 20px;
		background-color: rgba(255,255,255,.8);
		@media screen and (min-width:768px) {
			padding: 30px 50px 60px;
		}
	}
	&--table{
		width: 100%;
		border-collapse: collapse;
		@media screen and (min-width:768px) {
			font-size: 18px;
		}
		th,td{
			text-align: left;
			border-bottom: 1px solid rgba(#000, .15);
			display: block;
			padding-top: .7em;
			@media screen and (min-width:768px) {
				display: table-cell;
			}
		}
		th{
			@media screen and (min-width:768px) {
				padding-left: 10em;
			}
		}
		td{
			padding-top: 0.3em;
		}
	}
}
