.c-sectionTtl{
	margin-top: 2em;
	color: $color-brand;
	border-bottom: 1px solid rgba($color-brand, .32);
	margin-bottom: 1.25em;
	.main{
		padding-top: 0.25em;
		padding-left: 0.25em;
		line-height: 1;
		font-size: 26px;
		margin-top: 1em;
		margin-bottom: .25em;
		border-left: 8px solid rgba($color-brand, .52);
		display: flex;
		align-items: baseline;
		@media screen and (min-width:768px) {
			font-size: 37px;
		}
	}
	.small{
		font-size: 14px;
		padding-left: 2em;
		color: rgba($color-brand, .55);
		margin-left: auto;
		@media screen and (min-width:768px) {
			font-size: 18px;
		}
	}
	&__center{
		.main{
			text-align: center;
		}
	}
}
.c-pageTtl{
	background: rgba($color: #fff, $alpha: .5);
	padding: 1em;
	text-align: center;
	margin: auto;
	max-width: 475px;
	font-size: 26px;
	span{
		display: block;
	}
	@media screen and (min-width:768px) {
		font-size: 37px;
	}
}
