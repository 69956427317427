.top-mv{
	background-image: url(/img/mv-bg.png);
	padding-top: 40px;
	padding-bottom: 40px;
	@media screen and (min-width:768px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}
.top-mv__img{
	max-width: 500px;
	margin: auto;
	@media screen and (min-width:768px) {
		max-width: initial;
	}

}

.top-greeting{
	&.l-inner{
		max-width: 840px;
	}
	&--text{
		margin-left: auto;
		margin-right: auto;
		max-width: 590px;
		.lead{
			line-height: 2;
			@media screen and (min-width:768px) {
				text-align: center;
				font-size: 22px;
			}
			br{
				display: none;
				@media screen and (min-width:768px) {
					display: inline-block;
				}
			}
		}
	}
	&--sign{
		text-align: right;
		margin-top: 1em;
		@media screen and (min-width:768px) {
			text-align: center;
		}
	}
}
.top-greeting__author {
	padding: 1em;
	margin-top: 2em;
	display: block;
	background-color: #FAF6F5;
	border: 1px solid #CCB6AC;
}
.top-instagram{
	margin-top: 2em;
	vertical-align: middle;
	@media screen and (min-width:768px) {
		text-align: center;
		font-size: 20px;

	}
	img{
		height: 2.5em;
		vertical-align: middle;
	}
	a{
		text-decoration: underline;
		color: darkblue;
		font-weight: bold;
	}
}
